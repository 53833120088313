.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card{
  display: flex;
  width: 100%;
  /* max-width: 900px; */
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  background-color: #fff;
  max-height: "100%";
  overflow: hidden; 
  transition: box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  box-shadow: 3px 3px 40px rgb(44, 44, 44);
}


.card:hover{
  box-shadow: 3px 3px 60px rgb(44, 44, 44);
 }
 
 .card-content {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   gap: 10px;
   align-items: stretch;
   margin: 10px 0px 30px 10px;
 }

 .card-image {
  /* flex: 0.6; */
  height:100%;
  width:100px;
  align-self: center;
  /* border: 5px solid rgb(224, 222, 240); */
  box-shadow: 3px 3px 20px black;
}
 .card-img {
  border-right: 1px solid #ddd;
  transition: transform 3s ease;
  transform-style: preserve-3d;
}
.card-info {
  padding: 10px;
  text-align: left;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
}


.info-amnt{
  font-size: 25px;
  
}
.info-purch{
  font-size: 22px;
  
}

strong {
  font-weight: bold;
}

.info-btn{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; 
  gap: 20px;
 
}
  .card-info {
    flex: 1;
  }
  
  td:nth-child(1){
    font-weight: bold;
    padding-right: 10px;
    font-size: 25px;
  }
 
  td:nth-child(2){
    padding-right: 10px;
    font-size: 25px;
  }

  @media screen and (max-width: 500px) {
    .card-content {
      flex-direction: row;
      align-items: center;
    }
  
    .card-info {
      flex: 1;
    }
    .card-info .info-row span {
      color: #3aaf88; 
    }
  }